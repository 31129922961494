/*eslint no-restricted-globals:0*/
import auth0 from 'auth0-js'
import jwtDecode from 'jwt-decode'
const LOGIN_SUCCESS_PAGE = '/accounts';
const LOGIN_FAILURE_PAGE = '/login';
export default class Auth0 {
    auth0=new auth0.WebAuth({
        /*domain: "dev-i7ef65cc.auth0.com",
        clientID: "ZDO5sP217LYxWumXLbbLAMHAyz9YCMG2",
        redirectUri: "http://localhost:3000/callback",
        audience: "https://dev-i7ef65cc.auth0.com/userinfo",*/
        domain: "lexis.auth0.com",
        clientID: "uKxNWhOtR2xVTOp2m1RZYRss1DPIm2yp",
        redirectUri: "http://lqa.lexis.com.ec/callback",
        audience: "https://lexis.auth0.com/userinfo",
        responseType:"token id_token",
        scope: "openid profile"
    })

    login(){
        this.auth0.authorize();
    }

    handleAuthentication(){
        this.auth0.parseHash((err,authResults)=>{
            if (authResults && authResults.accessToken && authResults.idToken){
                let expiresAt= JSON.stringify((authResults.expiresIn)*1000+ new Date().getTime());
                sessionStorage.setItem("acces_token",authResults.accessToken);
                sessionStorage.setItem("id_token",authResults.idToken);
                sessionStorage.setItem("expires_at",expiresAt);
                sessionStorage.setItem("user",authResults.idTokenPayload.name);
                sessionStorage.setItem("picture",authResults.idTokenPayload.picture);
                location.hash='';
                location.pathname=LOGIN_SUCCESS_PAGE;
                console.log("autenticated");
                //console.log(authResults.idTokenPayload);
                //console.log(authResults.idTokenPayload.name);
                //console.log(authResults.);
            }
            else if (err){
                console.log(err);
                location.pathname=LOGIN_FAILURE_PAGE;
            }
        })
    }

    isAuthenticated(){
        let expires_at = JSON.parse(sessionStorage.getItem('expires_at'));
       // console.log(" expires_at: ", expires_at)
        return new Date().getTime()< expires_at;
    }

    logout(){
        sessionStorage.removeItem("acces_token");
        sessionStorage.removeItem("id_token");
        sessionStorage.removeItem("expires_at");
        sessionStorage.removeItem("user");
        sessionStorage.removeItem("picture");
        location.pathname=LOGIN_FAILURE_PAGE;
        this.auth0.logout({
            returnTo: process.env.REDIR_URL,
            clientID: 'uKxNWhOtR2xVTOp2m1RZYRss1DPIm2yp',
        });
    }

    getProfile(){
        if (localStorage.getItem("id_token")){
            return jwtDecode(localStorage.getItem("id_token"));
        }
        else {
            return{};
        }
    }
}
