import React, { Component } from "react";
import { ProductConsumer } from "../../../Context/Context";
import { MDBBtn, MDBContainer, MDBIcon } from "mdbreact";
export default class Logout extends Component {
    render() {
        return (
            
            <MDBContainer className={this.props.estilo}>
                <ProductConsumer>
                    {value => (
                        value.auth.isAuthenticated() ? (
                            <MDBBtn
                                color="info"
                                size="sm"
                                rounded
                                onClick={() => {
                                value.auth.logout();
                                }}
                            >
                                <MDBIcon icon="sign-out-alt" className="mr-2"></MDBIcon>
                                <span>Logout</span>
                            </MDBBtn>
                            ) : (
                            <React.Fragment />
                        )
                    )}
            </ProductConsumer>
        </MDBContainer>
        
        );
    }
}
