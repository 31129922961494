//Import React Library
import React, { Component } from 'react'

// Import ProductConsumer from Context Api (used for state management)
import { ProductConsumer } from "../../Context/Context"

// Import components from Material Design Bootstrap library for react
import {
    MDBModal,
    MDBModalBody,
    MDBModalHeader,
    MDBModalFooter,
    MDBBtn,
    MDBInput,
    MDBAlert,
    MDBContainer
} from "mdbreact";
var datos = {};


export default class CreateModalAccounts extends Component {

    state = {
        bodyResponse: "",
        colorAlert: "success",
        textAlert: "",
        visibility: "invisible"
    } //states used in MDBAlert

    componentDidMount() {
        
        this.resetAlert();
    }

    //Reset MDBAlert parameters
    resetAlert=()=>{
        this.setState(() => {
            return {
                colorAlert: "danger",
                textAlert: "",
                visibility: "invisible"
            };
        }); //Set alert fields to default
    }
    //handles the submit button form action
    handleSubmitForm = async (value, e) => {

        e.preventDefault(); // Prevent from page reloading

        //Retrieves values from editableArray and creates fills "datos" array fields
        var d1 = value.editableArray.emailAddress;
        var d2 = value.editableArray.userGroup
        var d3 = value.editableArray.role
        var d4 = value.editableArray.concurrencies;
        var d5 = value.editableArray.userObs;
        var d6 = value.editableArray.firstName;
        var d7 = value.editableArray.lastName;
        var d8 = value.editableArray.isActive;
        var d9 = value.editableArray.userSuscription;
        var d0 = value.editableArray.userAccount;
        var d10 = value.editableArray.userId;
        var apiAction = "";
        if (d10 > 0) {
            datos = { "userId": d10, "emailAddress": d1, "groupId": d2, "role": d3, "concurrencies": d4, "userObs": d5, "firstName": d6, "lastName": d7, "isActive": d8, "accountId": d0, "subscriptionId": d9 };

            apiAction = "/api/user/update"
        }
        else {
            datos = { "emailAddress": d1, "groupId": d2, "role": d3, "concurrencies": d4, "userObs": d5, "firstName": d6, "lastName": d7, "isActive": d8, "accountId": d0, "subscriptionId": d9 };

            apiAction = "/api/user/create"
        }


        console.log("datos:", datos)

        //Send post request to create a new account in LAM
        await fetch(value.url + apiAction, {
            method: "post",
            body: JSON.stringify(datos),
            headers: { 'Content-Type': 'application/json' }
        })
        .then(response => {
            if (!response.ok) {
                return response.json()
                    .catch(() => {
                        // Couldn't parse the JSON
                        console.log(response.status);
                    })
                    .then(({ message }) => {
                        // Got valid JSON with error response, use it
                        console.log(message || response.status);
                        this.setState(() => {
                            return {
                                colorAlert: "danger",
                                textAlert: message,
                                visibility: "visible"
                            };
                        }); //Set alert fields according to fetch response (state=error)
                    });
            }

            // Successful response, parse the JSON and return the data
            return response.json();
        })
        .then((json) => {
            console.log('parsed json', json)
            if (json != undefined) {
                this.setState(() => {
                    return {
                        colorAlert: "success",
                        textAlert: "Datos guardados exitosamente",
                        visibility: "visible"
                    };
                }); //Set alert fields according to fetch response (state=ok) 
            }

        })
           /* .then((body) => {
                console.log(body)
                console.log(body.UserId)
                if (body.UserId > 0) {
                    this.setState(() => {
                        return {
                            colorAlert: "success",
                            textAlert: "Datos guardados exitosamente",
                            visibility: "visible"
                        };
                    }); //Set alert fields according to fetch response (state=ok) 
                }
                else {
                    this.setState(() => {
                        return {
                            colorAlert: "danger",
                            textAlert: body.message,
                            visibility: "visible"
                        };
                    }); //Set alert fields according to fetch response (state=error)
                }


            });*/


    };
    render() {
        return (

            <React.Fragment>

                <ProductConsumer>
                    {value => (
                        <MDBModal
                            isOpen={value.modalUser}
                            onClick={() => {
                                //this.resetInput();
                                value.toggleModalUser();
                            }}
                            backdrop={true}
                            wrapClassName={"backdropFix"}
                            centered
                        >
                            <form
                                className="needs-validation"
                                onSubmit={e => this.handleSubmitForm(value, e)}
                            >
                                <MDBModalHeader
                                    className="text-center"
                                    titleClass="w-100 font-weight-bold"
                                    toggle={value.toggleModalUser}
                                    
                                >
                                    {value.editableArray.userId > 0
                                        ? "Editar Usuario"
                                        : "Nuevo Usuario"}
                                </MDBModalHeader>
                                <MDBModalBody>
                                    <MDBInput
                                        label="Email*"
                                        icon="jedi"
                                        type="text"
                                        validate
                                        name="emailAddress"
                                        error="wrong"
                                        success="right"
                                        onChange={e => value.handleInputChange(e)}


                                        value={value.editableArray.emailAddress}
                                    />

                                    <select //Account field

                                        className="browser-default custom-select mb-4"
                                        onChange={e => value.handleInputChange(e)}
                                        name="userAccount"  >
                                        {
                                            value.accounts.map(
                                                account => {
                                                    return (
                                                        <option value={account.accountId} key={account.accountId} >{account.name}</option>
                                                    )
                                                }
                                            )
                                        }
                                    </select>

                                    <select //Suscription field

                                        className="browser-default custom-select mb-4"
                                        onChange={e => value.handleInputChange(e)}
                                        name="userSuscription"  >
                                        {
                                            value.suscriptions.map(
                                                suscription => {
                                                    return (
                                                        <option value={suscription.subscriptionId} key={suscription.subscriptionId} >{suscription.description}</option>
                                                    )
                                                }
                                            )
                                        }
                                    </select>

                                    <select required className="browser-default custom-select mb-4" onChange={e => value.handleInputChange(e)} name="userGroup"  >
                                        {
                                            value.groups.map(
                                                group => {
                                                    return (
                                                        <option value={group.groupId} key={group.groupId} >{group.name}</option>
                                                    )
                                                }
                                            )
                                        }
                                    </select>

                                    <select className="browser-default custom-select mb-4" onChange={e => value.handleInputChange(e)} name="role"  >
                                        <option value="User" >User</option>
                                        <option value="Admin" >Admin</option>
                                    </select>

                                    <MDBInput
                                        label="No de Concurrentes*"
                                        icon="jedi"
                                        type="text"
                                        validate
                                        name="concurrencies"
                                        error="wrong"
                                        success="right"
                                        onChange={e => value.handleInputChange(e)}

                                        value={value.editableArray.concurrencies}
                                    />

                                    <MDBInput
                                        label="Observaciones"
                                        icon="jedi"
                                        type="text"
                                        validate
                                        name="userObs"
                                        error="wrong"
                                        success="right"
                                        onChange={e => value.handleInputChange(e)}


                                        value={value.editableArray.userObs}
                                    />

                                    <MDBInput
                                        label="Nombre*"
                                        icon="jedi"
                                        type="text"
                                        validate
                                        name="firstName"
                                        error="wrong"
                                        success="right"
                                        onChange={e => value.handleInputChange(e)}


                                        value={value.editableArray.firstName}
                                    />

                                    <MDBInput
                                        label="Apellido*"
                                        icon="jedi"
                                        type="text"
                                        validate
                                        name="lastName"
                                        error="wrong"
                                        success="right"
                                        onChange={e => value.handleInputChange(e)}


                                        value={value.editableArray.lastName}
                                    />

                                    <MDBContainer className="mb-5">
                                        <MDBInput  //is Active field
                                            label="Activo"
                                            type="checkbox"
                                            name="isActive"
                                            error="wrong"
                                            success="right"
                                            onChange={e => value.handleInputChange(e)}
                                            validate

                                        />
                                    </MDBContainer>

                                    <MDBAlert //Display query state (successfull or error)
                                        color={this.state.colorAlert} 
                                        className={this.state.visibility} >
                                        {this.state.textAlert}
                                    </MDBAlert>


                                </MDBModalBody>
                                <MDBModalFooter>
                                    <MDBBtn
                                        color="secondary"
                                        onClick={() => {
                                            // this.resetInput();
                                            value.toggleModalUser();
                                            this.resetAlert();
                                        }}
                                    >
                                        Cerrar
                                    </MDBBtn>
                                    <MDBBtn
                                        color="primary"
                                        type="submit" //onClick={() =>value.saveNewProduct(ProductData)}//
                                    >
                                        Guardar
                                    </MDBBtn>
                                </MDBModalFooter>
                            </form>
                        </MDBModal>
                    )}
                </ProductConsumer>





            </React.Fragment>

        )
    }
}
