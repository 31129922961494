import React, { Component } from 'react'

//Import material components
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';


//import react-router 
import { Link } from 'react-router-dom'

//impor components from material design bootstrap library for react
import {
    MDBContainer, MDBModalHeader, MDBModalBody, MDBInput, MDBAlert, MDBModalFooter, MDBBtn

} from "mdbreact";

// Import ProductConsumer from Context Api (used for state management)
import { ProductConsumer } from '../../Context/Context'
export default class LitigantUpdateUser extends Component {

    state = {
        bodyResponse: "",
        colorAlert: "success",
        textAlert: "",
        visibility: "invisible"
    } //states used in MDBAlert

    updateUser = async (e) => {
        e.preventDefault();
        var datos = { "email": e.target.email.value, "razonSocial": e.target.razonSocial.value, "nombres": e.target.nombres.value, "apellidos": e.target.apellidos.value, "estado":e.target.estado.value,"roles": [e.target.roles.value] };
        console.log(datos)
        await fetch("http://litigant.shardhive.io:8081/LegalSatCore/rest/authentication/updateUserState", {
            // await fetch("http://192.168.85.128:49461/api/v1/auth/validateToken", {
            method: "post",
            body: JSON.stringify(datos),
            headers:
            {
                'Content-Type': 'application/json',
                'Authorization': 'Basic bGVnYWxzYXQtY29yZTpkNzUwM2UzNy1jNTMwLTQwZDMtYWU4OC0zODg0ZTY5OTg1MzM='
            }
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .catch(() => {
                            // Couldn't parse the JSON
                            console.log(response.status);
                            this.setState(
                                {
                                    colorAlert: "danger",
                                    textAlert: response.status,
                                    visibility: "visible"
                                }
                            )
                        })
                        .then(({ message }) => {
                            // Got valid JSON with error response, use it
                            console.log(message || response.status);

                            this.setState(
                                {
                                    colorAlert: "danger",
                                    textAlert: message,
                                    visibility: "visible"
                                }
                            ); //Set alert fields according to fetch response (state=error)
                        });
                }

                // Successful response, parse the JSON and return the data
                return response.json();
            })
            .then((json) => {
                console.log('parsed json', json)
                if (json.success === "true") {
                    this.setState(
                        {
                            colorAlert: "success",
                            textAlert: json.message,
                            visibility: "visible"
                        }
                    ); //Set alert fields according to fetch response (state=error)
                }
                else {
                    this.setState(
                        {
                            colorAlert: "danger",
                            textAlert: json.message,
                            visibility: "visible"
                        }
                    ); //Set alert fields according to fetch response (state=error)
                }



            }
            )
    }
    render() {
        return (
            <React.Fragment>
                <ProductConsumer>
                    {(value) => //value contains the data defined in the product provider in Context.js
                        (

                            <React.Fragment>



                                <form
                                    className="needs-validation"
                                    onSubmit={e => this.updateUser(e)}

                                >
                                    <MDBModalHeader
                                        className="text-center"
                                        titleClass="w-100 font-weight-bold"
                                    >
                                        Actualización de Usuario en Litigant
                                    </MDBModalHeader>
                                    <MDBModalBody>
                                        <MDBInput //Account name field
                                            label="Nombres"
                                            icon="jedi"
                                            type="text"
                                            validate
                                            name="nombres"
                                            error="wrong"
                                            success="right"
                                        />

                                        <MDBInput //Account name field
                                            label="Apellidos"
                                            icon="jedi"
                                            type="text"
                                            validate
                                            name="apellidos"
                                            error="wrong"
                                            success="right"
                                        />

                                        <MDBInput //Account name field
                                            label="Email"
                                            icon="jedi"
                                            type="text"
                                            validate
                                            name="email"
                                            error="wrong"
                                            success="right"
                                        />

                                        <MDBInput //Account name field
                                            label="Razón Social"
                                            icon="jedi"
                                            type="text"
                                            validate
                                            name="razonSocial"
                                            error="wrong"
                                            success="right"
                                        />

                                        <MDBInput //Account name field
                                            label="Estado"
                                            icon="jedi"
                                            type="text"
                                            validate
                                            name="estado"
                                            error="wrong"
                                            success="right"
                                        />

                                        <MDBInput //Account name field
                                            label="Roles"
                                            icon="jedi"
                                            type="text"
                                            validate
                                            name="roles"
                                            error="wrong"
                                            success="right"
                                        />


                                        <MDBAlert  //Display query state (successfull or error)
                                            color={this.state.colorAlert}
                                            className={this.state.visibility}  >
                                            {this.state.textAlert}
                                        </MDBAlert>

                                    </MDBModalBody>
                                    <MDBModalFooter>
                                        <MDBBtn
                                            color="primary"
                                            type="submit" //onClick={() =>value.saveNewProduct(ProductData)}//
                                        >
                                            Actualizar usuario
                                    </MDBBtn>

                                    </MDBModalFooter>
                                </form>

                            </React.Fragment>
                        )
                    }
                </ProductConsumer>
            </React.Fragment>
        )
    }
}
