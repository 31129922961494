import React, { Component } from 'react'
import { ProductConsumer } from '../../Context/Context'
import { MDBBtn, MDBContainer,MDBIcon } from "mdbreact";
export default class Login extends Component {
    render() {
        return (
            <ProductConsumer>                
                {(value) => (
                    <MDBContainer className="text-center align-items-center mh-100">
                        <h4>Acceso restringido</h4>
                        <h5>Por favor inicie sesión para acceder a la información</h5>
                        <MDBBtn color="info" rounded onClick={() => { value.auth.login() }}>
                        <MDBIcon icon="sign-in-alt" className="mr-2"></MDBIcon>
                            Login
                        </MDBBtn>
                    </MDBContainer>                    
                )}      
            
            </ProductConsumer>
        )
    }
}
