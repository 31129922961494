//Import React Library
import React, { Component } from 'react'

// Import ProductConsumer from Context Api (used for state management)
import { ProductConsumer } from "../../Context/Context"

// Import components from Material Design Bootstrap library for react 
import {
    MDBModal,
    MDBModalBody,
    MDBModalHeader,
    MDBModalFooter,
    MDBBtn,
    MDBAlert
} from "mdbreact";


export default class ModalDelete extends Component {

    state = {
        bodyResponse: "",
        colorAlert: "success",
        textAlert: "",
        visibility: "invisible"
    }

    deleteProduct = async (key, e, layout, value) => {
        console.log("delete");
        console.log(key);
        console.log("layput :", layout);
        e.preventDefault(); // Prevent from page reloading
        var apiUrl = "";
        var respuesta="";       
        var message="";
        var apiId="";

        //Check item to delete
        if (layout == "Account") {
            console.log("account entra");
            apiUrl = "/api/account/delete";
            apiId="accountId"
        }
        else if (layout == "Subscription") {
            console.log("subscription entra");
            apiUrl = "/api/subscription/delete";
            apiId="subscriptionId"
        }
        else if (layout == "Group") {
            console.log("group entra");
            apiUrl = "/api/group/delete";
            apiId="groupId"
        }
        else if (layout == "User") {
            apiUrl = "/api/user/delete";
            apiId="userId"
        }

        //Send delete query
        await fetch(value.url + apiUrl, {
            method: "post",
            body: JSON.stringify({ [apiId]: key, "Notes": "Pruebas" }),
            headers: { 'content-type': 'application/json' }
        })
            .then(res => {
                respuesta=res})
            //.catch(error => { console.log(error) })
            .then((body) => {
                console.log(respuesta)
                console.log(respuesta.status)
                if (body!=undefined)
                {
                    message=body.message
                }
                else
                {
                    message="Error: "+ respuesta.statusText
                } //Set alert error message
                if (respuesta.status < 300) {
                    this.setState(() => {
                        return {
                            colorAlert: "success",
                            textAlert: "Datos eliminados exitosamente",
                            visibility: "visible"
                        };
                    }); //Set alert fields according to fetch response (state=ok) 
                }
                else {
                    this.setState(() => {
                        return {
                            colorAlert: "danger",
                            textAlert: message,
                            visibility: "visible"
                        };
                    }); //Set alert fields according to fetch response (state=error)
                }


            })
        }

    render() {
        return (
            <ProductConsumer>
                {(value) => (
                    <form
                        className='needs-validation'
                        onSubmit={(e) => this.deleteProduct(value.idDelete, e, this.props.layout, value)}
                    >
                        <MDBModal isOpen={value.modalDelete} centered onClick={() => { this.resetInput(); value.toggleModalDelete(); }} backdrop={true} wrapClassName={'backdropFix'}>
                            <MDBModalHeader
                                className="text-center"
                                titleClass="w-100 font-weight-bold"
                                toggle={value.toggleModalDelete}
                                required
                            >
                                Eliminar Item
                            </MDBModalHeader>
                            <MDBModalBody>
                                <h5>¿Está seguro que desea eliminar el ítem?</h5>
                            </MDBModalBody>
                            <MDBAlert color={this.state.colorAlert} className={this.state.visibility} >
                                {this.state.textAlert}
                            </MDBAlert>
                            <MDBModalFooter>
                                <MDBBtn color="secondary" onClick={() => { value.toggleModalDelete(); }}>Cancelar</MDBBtn>
                                <MDBBtn color="primary" type="submit" //
                                >Eliminar</MDBBtn>
                            </MDBModalFooter>
                        </MDBModal>
                    </form>
                )}
            </ProductConsumer>
        )
    }
}
