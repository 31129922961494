//Import React Library
import React, { Component } from 'react'

// Import ProductConsumer from Context Api (used for state management)
import { ProductConsumer } from "../../Context/Context"

import MDBTableP from "../Tables/Table"

// Import components from Material Design Bootstrap library for react 
import { MDBBtn, MDBCard, MDBCardBody, MDBIcon,  MDBCardHeader, MDBCardFooter } from 'mdbreact';

//Import modals
import ModalUsers from "../Modals/modalUsers"
import ModalUserVerify from "../Modals/modalUserVerify"
import ModalDelete from "../Modals/modalDelete"

export default class Accounts extends Component {


    render() {
        return (

            <React.Fragment>

                <MDBCard > {/* Render card component */}
                    <MDBCardHeader className="text-center">
                    <h4>Usuarios</h4>
                        {/* Render card header*/}
                    </MDBCardHeader>
                    <MDBCardBody>  {/* Render card content */}
                        <ProductConsumer>
                            {
                                value => {
                                    return (
                                        <MDBTableP datos={value.userData}>
                                            {/* Render TableCRUD component */}
                                        </MDBTableP>
                                    )
                                }
                            }
                        </ProductConsumer>

                    </MDBCardBody>

                    <MDBCardFooter> {/* Define card actions */}
                        <ProductConsumer>
                            {(value) => (
                                <MDBBtn color="info" rounded onClick={() => { value.toggleModalUser(null); }}>
                                    <MDBIcon icon="plus" className="mr-2"> </MDBIcon>
                                    Nuevo Usuario
                                </MDBBtn>
                            )}
                        </ProductConsumer>
                    </MDBCardFooter>

                </MDBCard>

                <ModalUserVerify/>
                <ModalUsers />
                <ModalDelete layout="User"/>
            </React.Fragment>

        )
    }
}
