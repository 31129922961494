//Import React Library
import React, { Component } from 'react'

// Import ProductConsumer from Context Api (used for state management)
import { ProductConsumer } from "../../Context/Context"

// Import components from Material Design Bootstrap library for react 
import {
    MDBModal,
    MDBModalBody,
    MDBModalHeader,
    MDBModalFooter,
    MDBBtn,
    MDBInput
} from "mdbreact";


export default class CreateModalAccounts extends Component {

    render() {
        return (

            <React.Fragment>

                <ProductConsumer>
                    {value => (
                        <MDBModal
                            isOpen={value.modalUserVerify}
                            onClick={() => {
                                //this.resetInput();
                                value.c();
                            }}
                            backdrop={true}
                            wrapClassName={"backdropFix"}
                            centered
                        >
                            <form
                                className="needs-validation"

                            >
                                <MDBModalHeader
                                    className="text-center"
                                    titleClass="w-100 font-weight-bold"
                                    toggle={value.toggleModalVerify}
                                    required
                                >
                                    Datos de Usuario
                                </MDBModalHeader>
                                <MDBModalBody>

                                    <MDBInput
                                        label="User ID"
                                        icon="jedi"
                                        type="text"
                                        validate
                                        name="lastName"
                                        error="wrong"
                                        success="right"
                                        disabled
                                        value={value.userInfo.UserId}
                                    />
                                    <MDBInput
                                        label="Email"
                                        icon="jedi"
                                        type="text"
                                        validate
                                        error="wrong"
                                        success="right"
                                        disabled
                                        value={value.userInfo.EmailAddress}
                                    />
                                    <MDBInput
                                        label="Nombre"
                                        icon="jedi"
                                        type="text"
                                        validate
                                        error="wrong"
                                        success="right"
                                        disabled
                                        value={value.userInfo.FirstName}
                                    />
                                    <MDBInput
                                        label="Apellido"
                                        icon="jedi"
                                        type="text"
                                        validate                                       
                                        error="wrong"
                                        success="right"
                                        disabled
                                        value={value.userInfo.LastName}
                                    />
                                    <MDBInput
                                        label="Subscripción"
                                        icon="jedi"
                                        type="text"
                                        validate
                                        error="wrong"
                                        success="right"
                                        disabled
                                        value={value.userInfo.SubscriptionName}
                                    />


                                </MDBModalBody>
                                <MDBModalFooter>
                                    <MDBBtn
                                        color="secondary"
                                        onClick={() => {
                                            // this.resetInput();
                                            value.toggleModalVerify();
                                        }}
                                    >
                                        Cerrar
                                    </MDBBtn>

                                </MDBModalFooter>
                            </form>
                        </MDBModal>
                    )}
                </ProductConsumer>
            </React.Fragment>

        )
    }
}
