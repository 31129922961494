import React, { Component } from 'react'

//Import material components
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';


//import react-router 
import { Link } from 'react-router-dom'

//impor components from material design bootstrap library for react
import {
    MDBContainer, MDBModalHeader, MDBModalBody, MDBInput, MDBAlert, MDBModalFooter, MDBBtn

} from "mdbreact";

// Import ProductConsumer from Context Api (used for state management)
import { ProductConsumer } from '../../Context/Context'
export default class LitigantCreateUser extends Component {

    state = {
        bodyResponse: "",
        colorAlert: "success",
        textAlert: "",
        visibility: "invisible"
    } //states used in MDBAlert

    verifyUser = async (e) => {
        e.preventDefault();
        var url= 'http://litigant.shardhive.io:8081/LegalSatCore/rest/authentication/users/'+e.target.email.value
      
        await fetch(url, {
            // await fetch("http://192.168.85.128:49461/api/v1/auth/validateToken", {
            method: "get",
           
            headers:
            {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Basic bGVnYWxzYXQtY29yZTpkNzUwM2UzNy1jNTMwLTQwZDMtYWU4OC0zODg0ZTY5OTg1MzM='
            }
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .catch(() => {
                            // Couldn't parse the JSON
                            console.log(response.status);
                            this.setState(
                                 {
                                    colorAlert: "danger",
                                    textAlert: response.status,
                                    visibility: "visible"
                                }
                            )
                        })
                        .then(({ message }) => {
                            // Got valid JSON with error response, use it
                           // console.log(message || response.status);

                            this.setState(
                                {
                                    colorAlert: "danger",
                                    textAlert: "message",
                                    visibility: "visible"
                                }
                            ); //Set alert fields according to fetch response (state=error)
                        });
                }
                console.log(response)
               
                // Successful response, parse the JSON and return the data
                return response.json();
            })
            .then((json) => {
                console.log('parsed json', json)
                if (json!==undefined)
                {
                    this.setState(
                        {
                            colorAlert: "success",
                            textAlert: 
                            <React.Fragment>
                                <p>id: {json.id}</p>                               
                                <p>Nombre: {json.firstName}</p>
                                <p>Apellido: {json.lastName}</p>
                                <p>Estado: {json.status}</p>
                                <p>Username: {json.username}</p>
                                <p>email: {json.email}</p>
                                <p>document: {json.document}</p>
                                <p>Roles: {json.roles}</p>
                                
                            </React.Fragment>,
                            visibility: "visible"
                        }
                    ); //Set alert fields according to fetch response (state=error)
                }
                else
                {
                    this.setState(
                        {
                            colorAlert: "danger",
                            textAlert: "El usuario no existe",
                            visibility: "visible"
                        }
                    ); //Set alert fields according to fetch response (state=error)
                }
                


            }
            )
    }
    render() {
        return (
            <React.Fragment>
                <ProductConsumer>
                    {(value) => //value contains the data defined in the product provider in Context.js
                        (

                            <React.Fragment>
                               


                                <form
                                    className="needs-validation"
                                    onSubmit={e => this.verifyUser(e)}
                                   
                                >
                                    <MDBModalHeader
                                        className="text-center"
                                        titleClass="w-100 font-weight-bold"
                                    >
                                        Verificación de usuario en Litigant
                                    </MDBModalHeader>
                                    <MDBModalBody>
                                        <MDBInput //Account name field
                                            label="Email"
                                            icon="jedi"
                                            type="text"
                                            validate
                                            name="email"
                                            error="wrong"
                                            success="right"
                                        />

                                        


                                        <MDBAlert  //Display query state (successfull or error)
                                            color={this.state.colorAlert}
                                            className={this.state.visibility}  >
                                            {this.state.textAlert}
                                        </MDBAlert>

                                    </MDBModalBody>
                                    <MDBModalFooter>                                       
                                        <MDBBtn
                                            color="primary"
                                            type="submit" //onClick={() =>value.saveNewProduct(ProductData)}//
                                        >
                                            Verificar usuario
                                    </MDBBtn>

                                    </MDBModalFooter>
                                </form>

                            </React.Fragment>
                        )
                    }
                </ProductConsumer>
            </React.Fragment>
        )
    }
}
