import React from 'react'; //Import React library
import ReactDOM from 'react-dom'; //Import DOM library
import './CSS/index.css'; //Import styles from index.css file
import "@fortawesome/fontawesome-free/css/all.min.css"; //Import fontawesome library
import "bootstrap-css-only/css/bootstrap.min.css"; //Import bootstrap library
import "mdbreact/dist/css/mdb.css"; //Import material design bootstrap react library
import App from './App'; // Import App.js file
import * as serviceWorker from './serviceWorker'; //Define a service worker
import {ProductProvider} from './Context/Context'; //Import context api
import {BrowserRouter as Router} from 'react-router-dom'; // Import elements for routing
ReactDOM.render(
    <ProductProvider>
        <Router> {/* Render route */} 
            <App /> {/* Render App.js */} 
        </Router>
    </ProductProvider>
    
, document.getElementById('root')); //Render on root

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
//