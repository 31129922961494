import React, { Component } from 'react'
import Auth from "../../Auth0/Auth0"

export default class Callback extends Component {
    
    componentDidMount(){
        const auth = new Auth();
        auth.handleAuthentication();
        
    }
    render() {
        return (
            <div>
                
            </div>
        )
    }
}
