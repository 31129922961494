import React, { Component } from 'react'

//Import material components
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';


//import react-router 
import { Link } from 'react-router-dom'

//impor components from material design bootstrap library for react
import {
    MDBIcon, MDBBtn

} from "mdbreact";

// Import ProductConsumer from Context Api (used for state management)
import { ProductConsumer } from '../../Context/Context'
export default class GetToken extends Component {

    getToken = async () => {
        var datos = { "clientId": "U3qvq7Ex+qRBNb0nplNOMj/orHg=", "clientTarget": "Va3l08n6+FdCZRcusswJvF1q6bI=", "email": "cesar_sevilla_ec@hotmail.com" }
        await fetch("http://lam.finder.lexis.com.ec/api/v1/auth/generateToken", {
            // await fetch("http://192.168.85.128:49461/api/v1/auth/validateToken", {
            method: "post",
            body: JSON.stringify(datos),
            headers: { 'Content-Type': 'application/json' }
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .catch(() => {
                            // Couldn't parse the JSON
                            console.log(response.status);

                        })
                        .then(({ message }) => {
                            // Got valid JSON with error response, use it
                            console.log(message || response.status);

                            /*this.setState(() => {
                                return {
                                    colorAlert: "danger",
                                    textAlert: message,
                                    visibility: "visible"
                                };
                            }); //Set alert fields according to fetch response (state=error)*/
                        });
                }

                // Successful response, parse the JSON and return the data
                return response.json();
            })
            .then((json) => {
                console.log('parsed json', json)
                if (json !== undefined) {
                    if (json.length === 0) {


                        /*  var decoded = jwtDecode(token);
                          console.log(decoded);
                          var producto = decoded.productName;*/

                        /*this.setState(() => {
                            return {
                                colorAlert: "success",
                                textAlert: "Datos guardados exitosamente",
                                visibility: "visible"
                            };
                        }); //Set alert fields according to fetch response (state=ok) */
                    }
                    else {
                        var strError = "";
                        console.log(json[0].message);
                        json.forEach(element => {
                            strError = strError + ", " + element.message
                        });
                        console.log(strError)

                    }
                }
                else {

                }


            }
            )
    }
    render() {
        return (
            <React.Fragment>
                <ProductConsumer>
                    {(value) => //value contains the data defined in the product provider in Context.js
                        (

                            <React.Fragment>
                                <p className="AsideSectionTitle px-3 pt-3 ">Obtener Token</p>
                                <MDBBtn color="info" rounded onClick={() => this.getToken()}>
                                    <MDBIcon icon="plus" className="mr-2"> </MDBIcon>
                                    Obtener Token
                                </MDBBtn>
                            </React.Fragment>
                        )
                    }
                </ProductConsumer>
            </React.Fragment>
        )
    }
}
