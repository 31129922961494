import React, { Component } from 'react'

//Import material components
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';


//import react-router 
import { Link } from 'react-router-dom'

//impor components from material design bootstrap library for react
import {
    MDBIcon

} from "mdbreact";

// Import ProductConsumer from Context Api (used for state management)
import { ProductConsumer } from '../../../Context/Context'


export default class Litigant extends Component {
    render() {
        return (
            <React.Fragment>
            <ProductConsumer>
                {(value) => //value contains the data defined in the product provider in Context.js
                    (

                        <React.Fragment>
                            <p className="AsideSectionTitle px-3 pt-3 ">Litigant</p>
                            <Link to='/litigant-create-user' > {/* Link to accounts */}
                                <ListItem button key="1"  >
                                    <MDBIcon icon="balance-scale" className="mr-3 SideBarItem" size="lg" />
                                    <ListItemText primary="Crear Usuario" className="SideBarItem" />
                                </ListItem>
                            </Link>
                            <Link to='/litigant-update-user' > {/* Link to accounts */}
                                <ListItem button key="2"  >
                                    <MDBIcon icon="balance-scale" className="mr-3 SideBarItem" size="lg" />
                                    <ListItemText primary="Editar Usuario" className="SideBarItem" />
                                </ListItem>
                            </Link>
                            <Link to='/litigant-verify-user' > {/* Link to accounts */}
                                <ListItem button key="3"  >
                                    <MDBIcon icon="balance-scale" className="mr-3 SideBarItem" size="lg" />
                                    <ListItemText primary="Verificar Usuario" className="SideBarItem" />
                                </ListItem>
                            </Link>
                           
                        </React.Fragment>
                    )
                }
            </ProductConsumer>
        </React.Fragment>
        )
    }
}
