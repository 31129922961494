import React from 'react'
import { MDBDataTable  } from 'mdbreact';

export default function Table(props) {
    return (
        <MDBDataTable
            striped
            bordered
            hover
            small
            data={props.datos}
        >
        
        </MDBDataTable>
    )
}