//Import React Library
import React, { Component } from "react";

//Import updater helper (used for updates a state of a field inside an array)
import update from "immutability-helper";

// Import components from Material Design Bootstrap library for react
import { MDBBtn, MDBIcon } from 'mdbreact';

//Import Auth0 component
import Auth from "../Auth0/Auth0"

// Create Context Api (used for state management)
const ProductContext = React.createContext();

//Define auth0 variable
const auth = new Auth();

//Define headers for accounts table
var accountHeaders = [

    {
        label: 'Id',
        field: 'accountId',
        sort: 'asc'
    },
    {
        label: 'Nombre',
        field: 'name'
    },
    {
        label: 'CRM Id',
        field: 'externalId'
    },
    {
        label: 'Fecha de Creación',
        field: 'createdDate'
    },
    {
        label: 'Fecha de Modificación',
        field: 'lastModified'
    },
    {
        label: 'Activo',
        field: 'isActive'
    },

    {
        label: 'Acción',
        field: 'accion'
    }
]

//Define headers for subscriptions table
var subscriptionHeaders = [

    {
        label: 'Id',
        field: 'subscriptionId',
        sort: 'asc'
    },
    {
        label: 'Cuenta Id',
        field: 'accountId'
    },
    {
        label: 'Descripción',
        field: 'description'
    },
    {
        label: 'Fecha de Inicio',
        field: 'startDate'
    },
    {
        label: 'Fecha de Fin',
        field: 'endDate'
    },
    {
        label: 'Fecha de Creación',
        field: 'createdDate'
    },
    {
        label: 'Fecha de Modificación',
        field: 'lastModified'
    },
    {
        label: 'Activo',
        field: 'isActive'
    },

    {
        label: 'Acción',
        field: 'accion'
    }
]

//Define headers for groups table
var groupHeaders = [

    {
        label: 'Id',
        field: 'groupId',
        sort: 'asc'
    },
    {
        label: 'Subscripción Id',
        field: 'subscriptionId',
        sort: 'asc'
    },
    {
        label: 'Cuenta Id',
        field: 'accountId'
    },
    {
        label: 'Nombre',
        field: 'name'
    },
    {
        label: 'Fecha de Creación',
        field: 'createdDate'
    },
    {
        label: 'Fecha de Modificación',
        field: 'lastModified'
    },
    {
        label: 'Activo',
        field: 'isActive'
    },

    {
        label: 'Acción',
        field: 'accion'
    }
]

//Define headers for users table
var userHeaders = [

    {
        label: 'Id',
        field: 'userId',
        sort: 'asc'
    },
    {
        label: 'Grupo Id',
        field: 'groupId',
        sort: 'asc'
    },
    {
        label: 'Subscripción Id',
        field: 'subscriptionId',
        sort: 'asc'
    },
    {
        label: 'Cuenta Id',
        field: 'accountId'
    },
    {
        label: 'Email',
        field: 'emailAddress'
    },
    {
        label: 'Nombre',
        field: 'firstName'
    },
    {
        label: 'Apellido',
        field: 'lastName'
    },
    {
        label: 'Rol',
        field: 'role'
    },
    {
        label: 'Fecha de Creación',
        field: 'createdDate'
    },
    {
        label: 'Fecha de Modificación',
        field: 'lastModified'
    },
    {
        label: 'Activo',
        field: 'isActive'
    },

    {
        label: 'Acción',
        field: 'accion'
    }
]


class ProductProvider extends Component {

    //State definition for all the app
    state = {
        products: [], //Stores the product data from LPM - Lexis Product Manager
        editableArray: [], // Saves temp data from form inputs
        accounts: [], //Stores the account data from LAM - Lexis Account Manager
        groups: [], //Stores groups data from LAM - Lexis Account Manager
        suscriptions: [], //Stores subscriptions data from LAM - Lexis Account Manager
        users: [], //Stores user data from LAM - Lexis Account Manager
        url: "http://lam.finder.lexis.com.ec:80",
        //url:"http://10.0.0.160:49461",
        urlLpm: "http://services.lpm.lexis.com.ec:80",
        modalAccount: false, //Toogle Account modal (Show modal on true, hide on false)
        modalSubscription: false, //Toogle Subscription  modal (Show modal on true, hide on false)
        modalGroup: false, //Toogle Group modal (Show modal on true, hide on false)
        modalUser: false, //Toogle User modal (Show modal on true, hide on false)
        accountData: [], //Stores the account data shown on modal account
        subscriptionData: [], //Stores the subscription data shown on modal account
        groupData: [], //Stores the group data shown on modal account
        userData: [], //Stores the user data shown on modal account
        modalDelete: false, //Toogle Delete modal (Show modal on true, hide on false)
        idDelete: 0,
        userInfo:[],  //Stores the user data shown on verify user modal
        modalUserVerify:false, //Toogle Verify modal (Show modal on true, hide on false),
        auth, //auth0 variable
        isAuthenticated: false, // Boolean for authentication state 

    }

    componentDidMount() {
        this.readAll();
        //Verify authentication before reading data from LPM and LAM
        if (this.state.auth.isAuthenticated())
        {
            this.readAll(); 
        }
         
    }
    
    readAll = () => {
        this.readProducts(); // Read products from  LPM
        this.readAccounts(); // Read accounts from LAM
        this.readSuscriptions(); // Read suscriptions from LAM
        this.readGroups(); // Read groups from LAM
        this.readUsers(); // Read users from LAM
    }

    //Function for reading product data from LPM
    readProducts = () => {
        let tempProducts = []; //Array used for storing temp data incoming from LPM
        fetch(this.state.urlLpm+ "/api/productos/nombres", {
            method: "post",
            body: JSON.stringify(""),
            headers: { 'Content-Type': 'application/json' }
        }) // fetch request sent to LPM
            .then(response => response.json())
            .then(data => {
                //console.log({ data });
                data.forEach(item => {
                    const singleItem = { ...item };
                    tempProducts = [...tempProducts, singleItem];
                    //console.log({ item }); //Stores incoming data from LPM to tempProducts array
                });

                this.setState(() => {
                    return {
                        products: tempProducts
                    };
                }); //Set temp data to products state
            });
    }


    //Reads data from LAM
    readAccounts = () => {
        var singleData;
        let datosRow = [];
        var value = this;
        let tempData = [];   //Array used for storing temp data incoming from LAM    
        fetch(this.state.url + "/api/account/list", {
            method: "post",
            body: JSON.stringify(""),
            headers: { 'Content-Type': 'application/json' }
        }) // fetch request sent to LAM
            .then(response => response.json())
            .then(data => {
                //console.log("Accounts: ", { data });
                data.forEach(item => {
                    const singleItem = { ...item };
                    tempData = [...tempData, singleItem];
                    //console.log({ item });

                    singleData =
                    {
                        "accountId": item.accountId,
                        "name": item.name,
                        "externalId": item.externalId,
                        "isActive": (item.isActive).toString(),
                        "createdDate": item.createdDate,
                        "lastModified": item.lastModified,
                        "accion": <React.Fragment>
                            <MDBBtn onClick={() => value.toggleModalAccount(item.accountId)}>
                                <MDBIcon icon="edit" size="sm"  ></MDBIcon>
                            </MDBBtn>
                            <MDBBtn onClick={() => value.toggleModalDelete(item.accountId)}>
                                <MDBIcon icon="trash" size="sm"  ></MDBIcon>
                            </MDBBtn>
                        </React.Fragment>

                    }
                    datosRow = [...datosRow, singleData];

                }); //Stores incoming data from LAM to tempData array

                var tempTable = {
                    columns: accountHeaders,
                    rows: datosRow
                }; // Merge headers and content for datatable
                this.setState(() => {
                    return {
                        accounts: tempData,
                        accountData: tempTable
                    };
                }); //Set temp data to accounts state             
            });
    }

    //Show/hide modal account and display data
    toggleModalAccount = idEdit => {
        let tempAccount = this.state.accounts.find(e => e.accountId === idEdit);
        console.log(tempAccount);
        this.setState(
            {
                editableArray: { ...tempAccount },
                modalAccount: !this.state.modalAccount
            }

        );
    };

    //Reads groups data from LAM
    readGroups = () => {
        var singleData;
        let datosRow = [];
        var value = this;
        let tempData = []; //Array used for storing temp data incoming from LAM       
        fetch(this.state.url + "/api/group/list", {
            method: "post",
            body: JSON.stringify(""),
            headers: { 'Content-Type': 'application/json' }
        }) // fetch request sent to LAM
            .then(response => response.json())
            .then(data => {
                //console.log("Groups: ", { data });
                data.forEach(item => {
                    const singleItem = { ...item };
                    tempData = [...tempData, singleItem];
                    //console.log({ item });
                    singleData =
                    {
                        "groupId": item.groupId,
                        "accountId": item.accountId,
                        "subscriptionId": item.subscriptionId,
                        "name": item.name,
                        "isActive": (item.isActive).toString(),
                        "createdDate": item.createdDate,
                        "lastModified": item.lastModified,
                        "accion": <React.Fragment>
                            <MDBBtn onClick={() => value.toggleModalGroup(item.groupId)}>
                                <MDBIcon icon="edit" size="sm"  ></MDBIcon>
                            </MDBBtn>
                            <MDBBtn onClick={() => value.toggleModalDelete(item.groupId)}>
                                <MDBIcon icon="trash" size="sm"  ></MDBIcon>
                            </MDBBtn>
                        </React.Fragment>

                    }

                    datosRow = [...datosRow, singleData];


                    //console.log("datosRow: ", datosRow)
                }); //Stores incoming data from LAM to tempData array
                var tempTable = {
                    columns: groupHeaders,
                    rows: datosRow
                };// Merge headers and content for datatable
                this.setState(() => {
                    return {
                        groups: tempData,
                        groupData: tempTable
                    };
                }); //Set temp data to groups state               
            });
    }

    //Show/hide modal group and display data
    toggleModalGroup = idEdit => {
        let tempGroup = this.state.groups.find(e => e.groupId === idEdit);
        console.log(tempGroup);
        this.setState(
            {
                editableArray: { ...tempGroup },
                modalGroup: !this.state.modalGroup
            }

        );
    };


    //Reads suscriptions data from LAM
    readSuscriptions = () => {
        var singleData;
        let datosRow = [];
        var value = this;
        let tempData = []; //Array used for storing temp data incoming from LAM        
        fetch(this.state.url + "/api/subscription/list", {
            method: "post",
            body: JSON.stringify(""),
            headers: { 'Content-Type': 'application/json' }
        }) // fetch request sent to LAM
            .then(response => response.json())
            .then(data => {
                console.log("Suscriptions: ", { data });
                data.forEach(item => {
                    const singleItem = { ...item };
                    tempData = [...tempData, singleItem];
                    //console.log({ item });

                    singleData =
                    {
                        "accountId": item.accountId,
                        "subscriptionId": item.subscriptionId,
                        "startDate": item.startDate,
                        "endDate": item.endDate,
                        "description": item.description,
                        "isActive": (item.isActive).toString(),
                        "createdDate": item.createdDate,
                        "lastModified": item.lastModified,
                        "accion": <React.Fragment>
                            <MDBBtn onClick={() => value.toggleModalSubscription(item.subscriptionId)}>
                                <MDBIcon icon="edit" size="sm"  ></MDBIcon>
                            </MDBBtn>
                            <MDBBtn onClick={() => value.toggleModalDelete(item.subscriptionId)}>
                                <MDBIcon icon="trash" size="sm"  ></MDBIcon>
                            </MDBBtn>
                        </React.Fragment>

                    }
                    datosRow = [...datosRow, singleData];


                    //console.log("datosRow: ", datosRow)
                }); //Stores incoming data from LAM to tempData array

                var tempTable = {
                    columns: subscriptionHeaders,
                    rows: datosRow
                }; // Merge headers and content for datatable
                this.setState(() => {
                    return {
                        suscriptions: tempData,
                        subscriptionData: tempTable
                    }; //Set temp data to suscriptions state
                });
            });
    }

    //Show/hide modal subscription and display data
    toggleModalSubscription = idEdit => {
        let tempData = this.state.suscriptions.find(e => e.subscriptionId === idEdit);
        console.log(tempData);
        this.setState(
            {
                editableArray: { ...tempData },
                modalSubscription: !this.state.modalSubscription
            }

        );
    };


    //Reads users data from LAM
    readUsers = () => {
        var singleData;
        let datosRow = [];
        var value = this;
        let tempData = []; //Array used for storing temp data incoming from LAM     
        fetch(this.state.url + "/api/user/list", {
            method: "post",
            body: JSON.stringify(""),
            headers: { 'Content-Type': 'application/json' }
        }) // fetch request sent to LAM
            .then(response => response.json())
            .then(data => {
                console.log("Users :", { data });
                data.forEach(item => {
                    const singleItem = { ...item };
                    tempData = [...tempData, singleItem];
                    console.log({ item });

                    singleData =
                    {
                        "userId": item.userId,
                        "groupId": item.groupId,
                        "accountId": item.accountId,
                        "subscriptionId": item.subscriptionId,
                        "emailAddress": item.emailAddress,
                        "firstName": item.firstName,
                        "lastName": item.lastName,
                        "role": item.role,
                        "concurrencies": item.concurrencies,
                        //"isActive": item.isActive.toString(),
                        "createdDate": item.createdDate,
                        "lastModified": item.lastModified,
                        "accion": <React.Fragment>
                            <MDBBtn onClick={() => value.toggleModalUser(item.userId)}>
                                <MDBIcon icon="edit" size="sm"  ></MDBIcon>
                            </MDBBtn>
                            <MDBBtn onClick={() => value.toggleModalDelete(item.userId)}>
                                <MDBIcon icon="trash" size="sm"  ></MDBIcon>
                            </MDBBtn>
                            <MDBBtn onClick={() => value.readUserInfo(item.userId)}>
                                <MDBIcon icon="info" size="sm"  ></MDBIcon>
                            </MDBBtn>

                        </React.Fragment>

                    }

                    datosRow = [...datosRow, singleData];


                    //console.log("datosRow: ", datosRow)
                }); //Stores incoming data from LAM to tempData array
                var tempTable = {
                    columns: userHeaders,
                    rows: datosRow
                }; // Merge headers and content for datatable
                this.setState(() => {
                    return {
                        users: tempData,
                        userData: tempTable
                    };
                });    //Set temp data to users state            
            });
    }

    //Read the profile of a desired user
    readUserInfo = idEdit => {        
        fetch(this.state.url + "/api/user/info", {
            method: "post",
            body: JSON.stringify({ "userId": idEdit}),
            headers: { 'Content-Type': 'application/json' }
        }) // fetch request sent to LAM
            .then(response => response.json())
            .then(data => {
                console.log("User :",  data );
                this.setState(() => {
                    return {
                        userInfo: data
                    };
                });    //Set temp data to users state            
            },this.toggleModalVerify());
        
    };

    //Show/hide modal Verify user and display data
    toggleModalVerify = () => {        
        this.setState(() => {
            return {                
                modalUserVerify: !this.state.modalUserVerify                
            };
        });    //Set temp data to users state 
    };

    //Show/hide modal user and display data
    toggleModalUser = idEdit => {
        let tempData = this.state.users.find(e => e.userId === idEdit);
        console.log(tempData);
        this.setState(
            {
                editableArray: { ...tempData },
                modalUser: !this.state.modalUser
            }

        );
    };

    //Show/hide modal delete and display data
    toggleModalDelete = id => {
        this.setState({
            modalDelete: !this.state.modalDelete,
            idDelete: id
        });

    };

    //Handles data incoming from input fields of forms
    handleInputChange = (event) => {
        const target = event.target;
        const valor = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        let newState = update(this.state, {
            editableArray: {
                [name]: { $set: valor }
            }
        }); //Updates state according to input data
        this.setState(newState);
    };


    render() {
        return (
            <ProductContext.Provider
                value={{
                    ...this.state,
                    handleInputChange: this.handleInputChange,
                    toggleModalAccount: this.toggleModalAccount,
                    toggleModalSubscription: this.toggleModalSubscription,
                    toggleModalGroup: this.toggleModalGroup,
                    toggleModalUser: this.toggleModalUser,
                    toggleModalDelete: this.toggleModalDelete,
                    toggleModalVerify:this.toggleModalVerify,
                    readAccounts: this.readAccounts,
                    readGroups: this.readGroups,
                    readSuscriptions: this.readSuscriptions,
                    readUsers: this.readUsers,
                    readAll: this.readAll,
                    readUserInfo:this.readUserInfo
                }} // Enable access to states and functions from ProductProvider
            >
                {this.props.children}
            </ProductContext.Provider>
        )
    }
}

//create context objet for consumer
const ProductConsumer = ProductContext.Consumer;

export { ProductProvider, ProductConsumer };
