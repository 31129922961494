//Import React Library
import React from 'react'

//Import prop types
import PropTypes from 'prop-types';

//Import material components
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';


//import react-router 
import { Link } from 'react-router-dom'

//impor components from material design bootstrap library for react
import {
    MDBIcon

} from "mdbreact";


// Import ProductConsumer from Context Api (used for state management)
import { ProductConsumer } from '../../../../Context/Context'

function SideBarList(props) {

    
    return (
        <React.Fragment>
            <ProductConsumer>
                {(value) => //value contains the data defined in the product provider in Context.js
                    ( 
                        
                        <React.Fragment>
                            <p className="AsideSectionTitle px-3 pt-3 ">LAM</p>
                            <Link to='/accounts' onClick={e => value.readAccounts()}> {/* Link to accounts */}
                                <ListItem button key="1"  >
                                    <MDBIcon icon="balance-scale" className="mr-3 SideBarItem" size="lg"/>
                                    <ListItemText primary="Cuentas" className="SideBarItem" />
                                </ListItem>
                            </Link>
                            <Link to='/subscriptions' onClick={e => value.readSuscriptions()}> {/* Link to suscriptions */}
                                <ListItem button key="1" >
                                    <MDBIcon icon="calendar-alt" className="mr-3 SideBarItem" size="lg"/>
                                    <ListItemText primary="Suscripciones" className="SideBarItem" />
                                </ListItem>
                            </Link>
                            <Link to='/groups' onClick={e => value.readGroups()}> {/* Link to groups */}
                                <ListItem button key="1" >
                                    <MDBIcon icon="users" className="mr-3 SideBarItem" size="lg"/>
                                    <ListItemText primary="Grupos" className="SideBarItem" />
                                </ListItem>
                            </Link>
                            <Link to='/users' onClick={e => value.readUsers()}> {/* Link to users */}
                                <ListItem button key="1" >
                                    <MDBIcon icon="user-tie" className="mr-3 SideBarItem" size="lg"/>
                                    <ListItemText primary="Usuarios" className="SideBarItem" />
                                </ListItem>
                            </Link>
                            

                        </React.Fragment>
                    )
                }
            </ProductConsumer>
        </React.Fragment>

    )
}

SideBarList.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    container: PropTypes.instanceOf(typeof Element === 'undefined' ? Object : Element),
};

export default SideBarList;
