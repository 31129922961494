//Import React Library
import React from 'react'

//Import material components
import Divider from '@material-ui/core/Divider';


//Import styles from styles.js file
import { useStyles } from "./styleSideBar"

//Import prop types
import PropTypes from 'prop-types';

//Import SideBarTitle
import SideBarTitle from "./SideBarTitle/SideBarTitle"
//Import SideBarList
import LAM from "./SideBarList/SideBarList"
import Autenticacion from "./Autenticacion"
import LogoutBtn from "../LogInOut/Logout"
import User from "./SideBarUser/SideBarUser"
import Litigant from "./Litigant"
function SideBar(props) {
    const classes = useStyles();
    return (

        <div className="ImageContainer"> {/* Render the background image of sidebar */}
            <div className="SideBarContent"> {/* Render rgba solid grenn background */}
                <SideBarTitle className={classes.toolbar} >
                    {/* Render side bar title */}
                </SideBarTitle>
                <Divider />
                <User />
                <Divider />
                <LAM>
                    {/* Render side item list */}
                </LAM>
                <Divider />
                <Autenticacion/>
                <Divider />
                <Litigant/>
                <Divider />
                <LogoutBtn estilo="d-block d-sm-none" />
            </div>
        </div>
    )
}

SideBar.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    container: PropTypes.instanceOf(typeof Element === 'undefined' ? Object : Element),
};

export default SideBar;
