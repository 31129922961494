//Import React Library
import React from 'react' 

import Typography from '@material-ui/core/Typography';

//Import prop types
import PropTypes from 'prop-types';

//Import styles from styles.js file
import { useStyles } from "./styleSideBarTitle"

function SideBarTitle(props) {
   
    const classes = useStyles();
        return (
            <Typography variant="h6" noWrap className={classes.brand}>
                LEXIS
            </Typography>
        )
   
}

SideBarTitle.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    container: PropTypes.instanceOf(typeof Element === 'undefined' ? Object : Element),
};

export default SideBarTitle;
