//Import React Library
import React, { Component } from 'react';

//Import Styles
import './CSS/App.css';

//Import NavigationDrawer reusable component (sidebar +content)
import NavigationDrawer from './Components/NavigationDrawer/NavigationDrawer' 

class App extends Component {

  render() {
    return (
      <React.Fragment> {/* Render a React Fragment (div)  */}
        <NavigationDrawer></NavigationDrawer> {/* Render the Navigation drawer component (SideBar + Content section)  */}
      </React.Fragment>
    );
  }
}

export default App;



