import React, { Component } from "react";
import { ProductConsumer } from "../../../../Context/Context";
import { MDBCol,MDBIcon  } from "mdbreact";
export default class SideBarUser extends Component {
    render() {
        return (
        <ProductConsumer>
            {value =>
            value.auth.isAuthenticated() ? (
                <div className="mx-auto">
                    
                    <MDBCol  className="mb-3 mt-3 text-center">
                        <img
                            //src="https://mdbootstrap.com/img/Photos/Avatars/img%20(9).jpg"
                            alt=""
                            src={localStorage.getItem('picture')}
                            className="rounded-circle img-fluid w-50"
                        />
                    </MDBCol >
                    <h5 className="text-center">{localStorage.getItem('user')}</h5>
                </div>
                
            ) : (
                <div className="mx-auto">
                    
                    <MDBCol  className="mb-3 mt-3 text-center">
                        <MDBIcon
                            icon="user-circle"
                            size="5x"
                            //src={localStorage.getItem('picture')}
                            className="rounded-circle img-fluid"
                        />
                    </MDBCol >
                    <h5 className="text-center">Usuario</h5>
                </div>
            )
            }
        </ProductConsumer>
        );
    }
    }
