//Import React Library
import React, { Component } from 'react'

// Import ProductConsumer from Context Api (used for state management)
import { ProductConsumer } from "../../../Context/Context"

//import layouts
import Accounts from "../../Layouts/Accounts";
import Subscriptions from "../../Layouts/Subscriptions";
import Groups from "../../Layouts/Groups";
import Users from "../../Layouts/Users";
import Login from "../../Layouts/Login";
import Callback from "../../Layouts/Callback";
import GetToken from "../../Layouts/GetToken"
import ValidateToken from "../../Layouts/ValidateToken"
import LitigantCreateUser from "../../Layouts/LitigantCreateUser"
import LitigantUpdateUser from "../../Layouts/LitigantUpdateUser"
import LitigantVerifyUser from "../../Layouts/LitigantVerifyUser"
//import react-router
import { Switch, Route } from "react-router-dom";

export default class Content extends Component {
    render() {
        return (
            <ProductConsumer>
                {value => //value contains the data defined in the product provider in Context.js
                    (
                        //Set URL path and define the component to display for each route
                        <Switch>
                            <Route exact path="/" component={Login} />
                            {value.auth.isAuthenticated() ? (
                                <Route path="/accounts" component={Accounts} />
                            ) : (
                                    <Route exact path="/" component={Login} />
                                )}
                            {value.auth.isAuthenticated() ? (
                                <Route path="/subscriptions" component={Subscriptions} />
                            ) : (
                                    <Route exact path="/" component={Login} />
                                )}
                            {value.auth.isAuthenticated() ? (
                                <Route path="/groups" component={Groups} />
                            ) : (
                                    <Route exact path="/" component={Login} />
                                )}
                            {value.auth.isAuthenticated() ? (
                                <Route path="/users" component={Users} />
                            ) : (
                                    <Route exact path="/" component={Login} />
                                )}
                            <Route path="/callback" component={Callback} />
                                          
                            {value.auth.isAuthenticated() &&<Route path="/get-token" component={GetToken} />}
                            {value.auth.isAuthenticated() &&<Route path="/validate-token" component={ValidateToken} />}                     
                            {value.auth.isAuthenticated() &&<Route path="/litigant-create-user" component={LitigantCreateUser} />}                     
                            {value.auth.isAuthenticated() &&<Route path="/litigant-update-user" component={LitigantUpdateUser} />}                     
                            {value.auth.isAuthenticated() &&<Route path="/litigant-verify-user" component={LitigantVerifyUser} />}                     
                           <Route component={Login} />
                            
                        </Switch>
                    )}
            </ProductConsumer>
        )
    }
}
