
import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 200;
export const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',   
    },
   
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
      backgroundColor:"var(--mainNavbarBg)",
      color:"var(--mainWhite)",
      
    },
   
    imageContainer:{
        backgroundImage:"url(/img/sidebarBG.jpg)",
        height:"100vh",
        /*WebkitFilter:"blur(1px)",
        msFilter:"blur(1px)",
        filter: "blur(1px)", */       
        display:"block"
    },

    toolbarContent:{
        backgroundColor:"var(--transparentNavbarBg)",
        height:"100vh",
        /*WebkitFilter:"blur(1px)",
        msFilter:"blur(1px)",
        filter: "blur(1px)", */       
        display:"block"
    },

    
    
  }));