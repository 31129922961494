import React, { Component } from 'react'

//Import material components
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';


//import react-router 
import { Link } from 'react-router-dom'

//impor components from material design bootstrap library for react
import {
    MDBIcon

} from "mdbreact";

// Import ProductConsumer from Context Api (used for state management)
import { ProductConsumer } from '../../../Context/Context'
export default class Autenticacion extends Component {
    render() {
        return (
            <React.Fragment>
                <ProductConsumer>
                    {(value) => //value contains the data defined in the product provider in Context.js
                        (

                            <React.Fragment>
                                <p className="AsideSectionTitle px-3 pt-3 ">Autenticación</p>
                                <Link to='/get-token' onClick={e => value.readAccounts()}> {/* Link to accounts */}
                                    <ListItem button key="1"  >
                                        <MDBIcon icon="balance-scale" className="mr-3 SideBarItem" size="lg" />
                                        <ListItemText primary="Obtener Token" className="SideBarItem" />
                                    </ListItem>
                                </Link>
                                <Link to='/validate-token' onClick={e => value.readAccounts()}> {/* Link to accounts */}
                                    <ListItem button key="1"  >
                                        <MDBIcon icon="balance-scale" className="mr-3 SideBarItem" size="lg" />
                                        <ListItemText primary="Validar Token" className="SideBarItem" />
                                    </ListItem>
                                </Link>
                            </React.Fragment>
                        )
                    }
                </ProductConsumer>
            </React.Fragment>
        )
    }
}
