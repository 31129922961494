//Import React Library
import React, { Component } from 'react'

// Import ProductConsumer from Context Api (used for state management)
import { ProductConsumer } from "../../Context/Context"

// Import components from Material Design Bootstrap library for react 
import {
    MDBModal,
    MDBModalBody,
    MDBModalHeader,
    MDBModalFooter,
    MDBBtn,
    MDBInput,
    MDBAlert,
    MDBContainer
} from "mdbreact";

var datos = {};


export default class CreateModalAccounts extends Component {

    state = {
        bodyResponse: "",
        colorAlert: "success",
        textAlert: "",
        visibility: "invisible"
    } //states used in MDBAlert

    componentDidMount() {

        this.resetAlert();
    }

    //Reset MDBAlert parameters
    resetAlert = () => {
        this.setState(() => {
            return {
                colorAlert: "danger",
                textAlert: "",
                visibility: "invisible"
            };
        }); //Set alert fields to default
    }
    //handles the submit button form action
    handleSubmitForm = async (value, e) => {

        e.preventDefault(); // Prevent from page reloading

        //Retrieves values from editableArray and creates fills "datos" array fields
        var d1 = value.editableArray.name;
        var d2 = value.editableArray.externalId
        var d3 = value.editableArray.accountObs
        var d4 = value.editableArray.isActive;
        var d0 = value.editableArray.accountId;
        var apiAction = "";

        //d0>0 ? user edit: user create
        if (d0 > 0) {
            datos = { "AccountId": d0, "name": d1, "ExternalId": d2, "Description": d3, "IsActive": d4, "StartDate": null, "EndDate": null, "Notes": "" };
            apiAction = "/api/account/update"
        }
        else {
            datos = { "name": d1, "ExternalId": d2, "Description": d3, "IsActive": d4, "StartDate": null, "EndDate": null, "Notes": "" };
            apiAction = "/api/account/create"
        }

        let b = 0;
        console.log("datos:", datos)

        //Send post request to create a new account in LAM
        await fetch(value.url + apiAction, {
            method: "post",
            body: JSON.stringify(datos),
            headers: { 'Content-Type': 'application/json' }
        })
            .then(response => {
                if (!response.ok) {
                    return response.json()
                        .catch(() => {
                            // Couldn't parse the JSON
                            console.log(response.status);
                        })
                        .then(({ message }) => {
                            // Got valid JSON with error response, use it
                            console.log(message || response.status);
                            this.setState(() => {
                                return {
                                    colorAlert: "danger",
                                    textAlert: message,
                                    visibility: "visible"
                                };
                            }); //Set alert fields according to fetch response (state=error)
                        });
                }

                // Successful response, parse the JSON and return the data
                return response.json();
            })
            .then((json) => {
                console.log('parsed json', json)
                if (json != undefined) {
                    this.setState(() => {
                        return {
                            colorAlert: "success",
                            textAlert: "Datos guardados exitosamente",
                            visibility: "visible"
                        };
                    }); //Set alert fields according to fetch response (state=ok) 
                }

            })
        // console.log(headers.entries())
        /*console.log(body.ExternalId)
        if (body.ExternalId > 0) {
            this.setState(() => {
                return {
                    colorAlert: "success",
                    textAlert: "Datos guardados exitosamente",
                    visibility: "visible"
                };
            }); //Set alert fields according to fetch response (state=ok) 
        }
        else {
            this.setState(() => {
                return {
                    colorAlert: "danger",
                    textAlert: body.message,
                    visibility: "visible"
                };
            }); //Set alert fields according to fetch response (state=error)
        }


    });*/


    };
    render() {
        return (

            <React.Fragment>

                <ProductConsumer>
                    {value => (
                        <MDBModal
                            isOpen={value.modalAccount}
                            onClick={() => {
                                //this.resetInput();
                                value.toggleModalAccount();
                            }}
                            backdrop={true}
                            wrapClassName={"backdropFix"}
                            centered
                        >
                            <form
                                className="needs-validation"
                                onSubmit={e => this.handleSubmitForm(value, e)}
                            >
                                <MDBModalHeader
                                    className="text-center"
                                    titleClass="w-100 font-weight-bold"
                                    toggle={value.toggleModalAccount}

                                >
                                    {value.editableArray.accountId > 0
                                        ? "Editar Cuenta"
                                        : "Nueva Cuenta"}
                                </MDBModalHeader>
                                <MDBModalBody>
                                    <MDBInput //Account name field
                                        label="Nombre*"
                                        icon="jedi"
                                        type="text"
                                        validate
                                        name="name"
                                        error="wrong"
                                        success="right"
                                        onChange={e => value.handleInputChange(e)}


                                        value={value.editableArray.name}
                                    />

                                    <MDBInput //Zoho id CRM field
                                        label="Id (CRM)*"
                                        icon="jedi"
                                        type="text"
                                        validate
                                        name="externalId"
                                        error="wrong"
                                        success="right"
                                        onChange={e => value.handleInputChange(e)}

                                        value={value.editableArray.externalId}
                                    />

                                    <MDBInput //Observations field
                                        label="Observaciones"
                                        icon="jedi"
                                        type="text"
                                        validate
                                        name="accountObs"
                                        error="wrong"
                                        success="right"
                                        onChange={e => value.handleInputChange(e)}


                                        value={value.editableArray.accountObs}
                                    />

                                    <MDBContainer className="mb-5">
                                        <MDBInput  //is Active field
                                            label="Activo"
                                            type="checkbox"
                                            name="isActive"
                                            error="wrong"
                                            success="right"
                                            onChange={e => value.handleInputChange(e)}
                                            validate

                                        />
                                    </MDBContainer>

                                    <MDBAlert  //Display query state (successfull or error)
                                        color={this.state.colorAlert}
                                        className={this.state.visibility}  >
                                        {this.state.textAlert}
                                    </MDBAlert>

                                </MDBModalBody>
                                <MDBModalFooter>
                                    <MDBBtn
                                        color="secondary"
                                        onClick={() => {
                                            // this.resetInput();
                                            value.toggleModalAccount();
                                            this.resetAlert();
                                        }}
                                    >
                                        Cerrar
                                    </MDBBtn>
                                    <MDBBtn
                                        color="primary"
                                        type="submit" //onClick={() =>value.saveNewProduct(ProductData)}//
                                    >
                                        Guardar
                                    </MDBBtn>
                                </MDBModalFooter>
                            </form>
                        </MDBModal>
                    )}
                </ProductConsumer>
            </React.Fragment>
        )
    }
}
