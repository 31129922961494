
import { makeStyles } from '@material-ui/core/styles';


export const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',   
    },
   
    brand:{
        margin:"right",
        textAlign:"center",
        verticalAlign:"middle",
        lineHeight: "320%", 
        
    },
  
 

    
    
  }));